<div class="container-xxl px-4 px-xxl-2 py-3 d-flex animated-div">
    <div class="d-lg-grid content" id="content">
        <div class="sidebar d-none d-sm-block">
            <div class="masthead py-5">
                <div class="mb-4 text-center text-lg-start">
                    <a class="img-blog" href="/" aria-label="Bootstrap">
                        <img class="bd-booticon d-block mx-auto mb-3 mx-lg-0" src="../../assets/img/Blogger.gif"
                            alt="Bootstrap">
                    </a>
                    <div class="ms-3 ms-lg-0">
                        <h1 class="mb-1 mb-lg-2 f1 fw-600">Blog</h1>
                        <p class="col-sm-8 col-lg-12 mx-auto mb-0 mb-lg-4">Aquí encontrarás explicaciones sencillas y
                            claras sobre diversos
                            conceptos tecnológicos.
                        </p>
                    </div>
                </div>





            </div>

        </div>
        <div class="main">
            <div class="posts-container mx-auto  py-5 my-5">
                <div class="posts">
                    <div class="post">
                        <h1 class="h1">
                            Glosario
                        </h1>

                        <div class="d-flex align-items-center mb-4 text-muted author-info">
                            <a class="d-flex align-items-center text-muted text-decoration-none" target="_blank"
                                rel="noopener">
                                <img class="mb-0 me-2 rounded-2"
                                    src="https://avatars.githubusercontent.com/u/110430552?v=4" alt="" width="32"
                                    height="32">
                                <span>victor-maza</span>
                            </a>
                            <span class="d-flex align-items-center ms-3" title="20 Feb 24 15:22 UTC">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                    class="me-2" viewBox="0 0 16 16">
                                    <path
                                        d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z">
                                    </path>
                                    <path
                                        d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z">
                                    </path>
                                </svg>
                                February 20, 2024</span>
                        </div>




                        <p>
                            Bienvenido al glosario. Este espacio está diseñado para ayudarte a entender mejor los
                            términos
                            comunes que podrías encontrar al utilizar dispositivos electrónicos y servicios digitales.
                        </p>

                        <div class="card">

                            <div class="card-body">
                                <h2 id="highlights" class="py-3" id="palabras-claves">Palabras Clave</h2>
                                <ul>
                                    <li><strong>Megapíxeles (MP)</strong>: Se refiere a la cantidad de pequeños puntos,
                                        llamados
                                        píxeles, que forman una imagen. Cuantos más megapíxeles tenga una cámara, más
                                        detallada
                                        será la imagen.</li>
                                    <li><strong>PDAF (Phase Detection Auto Focus)</strong>: Es una tecnología que
                                        permite a las
                                        cámaras enfocar más rápido y de manera más precisa, especialmente en situaciones
                                        de
                                        movimiento.</li>
                                    <li><strong>Wide (Gran angular)</strong>: Se refiere a un tipo de lente de cámara
                                        que
                                        captura una vista más amplia de la escena. Es ideal para paisajes y fotos
                                        grupales.</li>
                                    <li><strong>Ultrawide (Ultra gran angular)</strong>: Similar al gran angular, pero
                                        captura
                                        una vista aún más amplia. Perfecto para capturar paisajes panorámicos.</li>
                                    <li><strong>Profundidad</strong>: En fotografía, se refiere a la distancia entre el
                                        objeto
                                        más cercano y el más lejano en una foto que aparece nítido. Una buena cámara de
                                        profundidad puede hacer que el fondo sea borroso para resaltar el objeto
                                        principal.</li>
                                    <li><strong>f/1.8</strong>: Este número se refiere a la apertura de la lente de una
                                        cámara.
                                        Un número menor como f/1.8 significa que la lente puede dejar entrar más luz, lo
                                        que es
                                        útil en condiciones de poca luz y para desenfocar el fondo.</li>
                                    <li><strong>FPS (Frames Per Second)</strong>: Se refiere a la cantidad de imágenes
                                        individuales que una cámara puede capturar por segundo. Más FPS significa videos
                                        más
                                        suaves y fluidos.</li>
                                    <li><strong>1080P</strong>: Es una resolución de video que indica la calidad de la
                                        imagen.
                                        1080p significa que el video tiene 1080 líneas de píxeles de alto, ofreciendo
                                        una imagen
                                        clara y detallada.</li>
                                    <li><strong>LCD (Liquid Crystal Display)</strong>: Es un tipo de pantalla que se usa
                                        en
                                        muchos dispositivos electrónicos. Es conocida por ser delgada y eficiente en
                                        energía.
                                    </li>
                                    <li><strong>IPS (In-Plane Switching)</strong>: Es una tecnología usada en pantallas
                                        LCD que
                                        ofrece mejores colores y ángulos de visión. Las pantallas IPS se ven bien desde
                                        casi
                                        cualquier ángulo.</li>
                                    <li><strong>Touchscreen Capacitivo</strong>: Es un tipo de pantalla táctil que
                                        detecta la
                                        presión de tus dedos usando una capa de electricidad. Es más precisa y responde
                                        mejor
                                        que otras pantallas táctiles.</li>
                                    <li><strong>GPU (Graphics Processing Unit)</strong>: Es un componente en
                                        dispositivos
                                        electrónicos que maneja los gráficos y las imágenes. Hace que los juegos y
                                        videos se
                                        vean mejor y funcionen más suavemente.</li>
                                    <li><strong>mAh (miliamperios-hora)</strong>: Es una medida de la capacidad de una
                                        batería.
                                        Más mAh significa que la batería puede durar más tiempo antes de necesitar
                                        recargarse.
                                    </li>
                                    <li><strong>GHz (Gigahercios)</strong>: Es una unidad de medida para la velocidad de
                                        un
                                        procesador en dispositivos electrónicos. Más GHz significa que el dispositivo
                                        puede
                                        realizar tareas más rápido.</li>
                                    <li><strong>SIM (Subscriber Identity Module)</strong>: Es una pequeña tarjeta que se
                                        inserta
                                        en un teléfono móvil. Contiene información del usuario y permite al teléfono
                                        conectarse
                                        a la red de un operador.</li>
                                    <li><strong>USB (Universal Serial Bus)</strong>: Es un estándar para cables y
                                        conexiones que
                                        permite transferir datos y cargar dispositivos. Es común en computadoras,
                                        teléfonos y
                                        muchos otros dispositivos electrónicos.</li>
                                </ul>

                            </div>


                        </div>








                        <div class="d-flex justify-content-center py-5">
                            <hr class="w-50" id="primer-uso">
                        </div>


                        <div class="card py-3">
                            <div class="card-body">

                                <h2>Primeros Pasos</h2>
                                <p>¡Felicidades por tu nuevo equipo! Aquí tienes una guía sencilla para empezar a usarlo.</p>
                                
                                <h5>Abrir la Caja</h5>
                                <ol>
                                    <li><strong>Abrí la caja:</strong> Colocá el equipo en una mesa o lugar seguro. Deslizá la tapa hacia arriba para abrir la caja y ver tu nuevo equipo.</li>
                                </ol>
                                
                                <h5>Preparar el Celular</h5>
                                <ol>
                                    <li><strong>Retirá el film protector:</strong> Es recomendable que adquieras un vidrio templado para más protección.</li>
                                    <li><strong>Uso de batería:</strong> Usá el equipo normalmente. No es necesario una carga inicial al 100% para comenzar a utilizar tu equipo, ni esperar que se agote la batería y volverlo a cargar.</li>
                                </ol>
                                
                                <h5>Encender y Configurar</h5>
                                <ol>
                                    <li><strong>Encendé el celular:</strong> Mantené presionado el botón de encendido hasta que aparezca el logo de la marca.</li>
                                    <li><strong>Selecciona el idioma:</strong> Elegí tu idioma de preferencia.</li>
                                    <li><strong>Conéctate a Wi-Fi:</strong> Selecciona tu red Wi-Fi e ingresa la contraseña para conectarte a internet.</li>
                                    <li><strong>Inicia sesión con tu cuenta de Google:</strong> Si tenés una cuenta de Google, ingresa tus datos para sincronizar tus contactos y aplicaciones.</li>
                                    <li><strong>Configurá la seguridad:</strong> Elegí un método de seguridad como PIN, patrón o huella dactilar para proteger tu celular.</li>
                                </ol>
                                
                                <h5>Actualizar el Sistema</h5>
                                <ol>
                                    <li><strong>Buscá actualizaciones:</strong> Ve a <strong>Configuración > Sistema > Actualizaciones</strong> y revisa si hay actualizaciones disponibles.</li>
                                    <li><strong>Instalá las actualizaciones:</strong> Si hay actualizaciones disponibles, instálalas para asegurar que tu celular funcione correctamente y tenga las últimas funciones y mejoras de seguridad.</li>
                                </ol>
                                
                                <h5>Listo para Usar</h5>
                                <p>¡Ahora tu smartphone está listo para usar! Explorá las aplicaciones y ajustá la configuración según tus preferencias.</p>
                                
                            </div>

                        </div>





                        <div class="d-flex justify-content-center py-5">
                            <hr class="w-50" id="limpieza">
                        </div>



                        <div class="card">

                            <div class="card-body">
                                <h2>Guía para Limpiar tu Celular</h2>
                                <p>Mantener tu celular limpio es importante para tu salud y su buen funcionamiento.
                                    Sigue
                                    estos
                                    pasos para limpiarlo de manera segura y efectiva.</p>

                                <h5>Materiales Necesarios:</h5>
                                <ul>
                                    <li>Paño de microfibra suave</li>
                                    <li>Alcohol isopropílico (al menos 70%)</li>
                                    <li>Agua destilada</li>
                                    <li>Botella con atomizador</li>
                                    <li>Hisopos de algodón</li>
                                    <li>Palillos de dientes (opcional)</li>
                                </ul>

                                <h5>Pasos a Seguir:</h5>
                                <ol>
                                    <li><strong>Apaga el Celular:</strong> Apaga tu dispositivo para evitar cualquier
                                        daño
                                        eléctrico.</li>
                                    <li><strong>Retira Accesorios:</strong> Quita la funda y otros accesorios como
                                        auriculares,
                                        cables y tarjetas SIM.</li>
                                    <li><strong>Preparación de la Solución de Limpieza:</strong> Mezcla partes iguales
                                        de
                                        alcohol isopropílico y agua destilada en una botella con atomizador.</li>
                                    <li><strong>Limpieza de la Pantalla y el Cuerpo del Celular:</strong> Rocía una
                                        pequeña
                                        cantidad de la solución en un paño de microfibra y limpia suavemente.</li>
                                    <li><strong>Limpieza de los Puertos y Ranuras:</strong> Usa un hisopo de algodón
                                        humedecido
                                        con la solución para limpiar los puertos. Usa un palillo de dientes si es
                                        necesario.
                                    </li>
                                    <li><strong>Limpieza de la Cámara:</strong> Limpia las lentes con un paño de
                                        microfibra
                                        seco. Si es necesario, usa la solución de limpieza.</li>
                                    <li><strong>Limpieza de la Funda del Celular:</strong> Lava la funda con agua y
                                        jabón
                                        suave.
                                        Sécala completamente antes de volver a colocarla.</li>
                                    <li><strong>Secado Final:</strong> Deja que tu celular se seque completamente antes
                                        de
                                        encenderlo o colocar la funda y otros accesorios.</li>
                                </ol>

                                <h5>Consejos Adicionales:</h5>
                                <ul>
                                    <li>Limpia tu celular regularmente para mantenerlo libre de gérmenes y suciedad.
                                    </li>
                                    <li>Evita el uso de productos químicos agresivos y materiales abrasivos.</li>
                                    <li>No sumerjas el celular en ningún líquido, incluso si es resistente al agua.</li>
                                </ul>


                            </div>




                        </div>

                        
                        <div class="d-flex justify-content-center py-5">
                            <hr class="w-50" id="st">
                        </div>




                        <div class="card">

                            <div class="card-body">

                                <h2>Servicios tecnicos Oficiales</h2>
                                <ul>
                                    <li><a href="https://maps.app.goo.gl/Wga4fyALFoVh5Wnz9" target="_blank">Iphone</a>
                                    </li>

                                    <li><a href="https://g.co/kgs/GgEPqZn" target="_blank">Samsung</a></li>

                                    <li><a href="https://maps.app.goo.gl/EJyGVhkEDvqJDLmJ8" target="_blank">Motorola</a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>