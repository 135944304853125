<header id="hero" class="d-flex align-items-center">
    <div class="container">
        <div class="row justify-content-center ">
            <div class="col-12 col-md-6 py-5">
                <div class="text-center text-md-start animated-div">
                    <h1 class="display-md-2 display-4 fw-bold text-dark pb-2 ">
                        <span class="text-danger">Comprá </span>de manera inteligente
                    </h1>
                    <p class="lead">
                        Empezá a buscar tu próximo smartphone
                    </p>

                </div>
            </div>
            <div class="col-12 col-md-6 d-none d-md-block background-gradient">
                <!-- Este div ahora tiene una clase para aplicar el fondo de degradado -->
            </div>
        </div>
    </div>
</header>
<div class="b-example-divider"></div>
<div class="slider">
    <div class="slide-track">
        <div class="slide">
            <img src="../../assets/logos/1.png" alt="">
        </div>
        <div class="slide">
            <img src="../../assets/logos/2.png" alt="">
        </div>
        <div class="slide">
            <img src="../../assets/logos/3.png" alt="">
        </div>

        <div class="slide">
            <img src="../../assets/logos/4.png" alt="">
        </div>
        <div class="slide">
            <img src="../../assets/logos/5.png" alt="">
        </div>
        <div class="slide">
            <img src="../../assets/logos/6.png" alt="">
        </div>

        <div class="slide">
            <img src="../../assets/logos/1.png" alt="">
        </div>
        <div class="slide">
            <img src="../../assets/logos/2.png" alt="">
        </div>
        <div class="slide">
            <img src="../../assets/logos/3.png" alt="">
        </div>

        <div class="slide">
            <img src="../../assets/logos/4.png" alt="">
        </div>
        <div class="slide">
            <img src="../../assets/logos/5.png" alt="">
        </div>
        <div class="slide">
            <img src="../../assets/logos/6.png" alt="">
        </div>

    </div>
</div>
<div class="b-example-divider"></div>

@defer (on viewport) {<div class="container my-5 animated-div">
    <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
        <div class="col-12 col-lg-7 p-3 p-lg-5 pt-lg-3 text-center text-lg-start">
            <h1 class="display-4 fw-bold lh-1 text-body-emphasis">Más de <span class="text-danger">6 años</span>
                conectando Córdoba</h1>
            <p class="lead">Desde hace más de 6 años, ofrecemos los últimos dispositivos móviles y servicio al cliente
                en Córdoba. Encontrarás las últimas novedades en tecnología y una atención personalizada que te brindará
                la seguridad y confianza que necesitas.</p>
            <div class="d-grid gap-2 d-md-flex justify-content-center justify-content-lg-start mb-4 mb-lg-3">
                <button type="button" class="btn btn-success btn-lg px-4 me-md-2 fw-bold"
                onclick="window.open('https://wa.me/5493512129922?text=Hola%21%20Vengo%20desde%20la%20p%C3%A1gina%20😃%20tengo%20algunas%20consultas%20para%20hacerte...', '_blank');">Whatsapp</button>
                <button type="button" class="btn btn-outline-secondary btn-lg px-4"
                    onclick="location.href='warranty';">Garantía</button>
            </div>
        </div>

        <div class="col-lg-4 d-flex justify-content-center">
            <a class="text-body-secondary" href="https://www.instagram.com/cordobacelularesoficial" target="_blank"><img
                class="img-fluid" src="../../assets/img/newinsta.gif" alt="" style="height: 1000; width: 50;">
            </a>
        </div>
    </div>
</div>
} @placeholder {<p>Cargando</p>}