
<body class="animated-div py-3">
  
    
    <div class="col-lg-8 mx-auto p-4 py-md-5 ">
      
        <main>
            <h2 class="pt-5">Condiciones de Garantía</h2>
            <ol class="py-3">
                <li>
                    <p>A) Los productos cuentan con 3 MESES (90 DÍAS) de garantía. No deben presentar daños estéticos, caídas, golpes 
                    y/o rayaduras, haber sido mojado y luego secado a partir de la fecha de compra.</p>
                    <p>B) LOS MÓDULOS (PANTALLAS DE LOS EQUIPOS) CUENTAN CON 7 DÍAS DE GARANTÍA, la cual será efectiva 
                    si en la misma llegasen a aparecer: líneas verticales, puntos negros o verdes, problemas de imagen, falla en el táctil, 
                    y/o cualquier anormalidad que haya surgido de forma espontánea (sin haber sufrido golpes, caídas, ni nada de lo 
                    mencionado en el ítem 1-A).</p>
                    <p>C) Todos los accesorios cuentan con 7 DÍAS de garantía desde la fecha de compra.</p>
                </li>
                <li>
                    <p>A) La garantía ÚNICAMENTE cubre cualquier mal funcionamiento, falla o desperfecto del equipo, siempre y cuando 
                    no se dé ninguno de los supuestos anteriores, mencionados en el ítem 1. Caso contrario, Córdoba Celulares queda 
                    exento de responsabilidad alguna.</p>
                    <p>B) La garantía quedará sin efecto si el equipo fue sumergido en agua y/o cualquier líquido, aun así el aparato se 
                    encuentre dentro de la categoría de ‘’equipos sumergibles’’.</p>
                </li>
                <li>
                    <p>A) En caso de cumplir con todos los requisitos para la garantía, el equipo se debe presentar con su respectiva caja y 
                    accesorios.</p>
                    <p>B) Al hacer entrega del teléfono, el mismo no debe contener contraseñas, pin, huella dactilar, desbloqueo de 
                    reconocimiento facial, ni cuentas activas (Gmail, iCloud, etc.).</p>
                    <p>C) Ya recibido el producto para garantía, será revisado en un lapso de 7 DÍAS HÁBILES. Una vez detectada la falla y 
                    comprobado que es un problema de fábrica (y no de módulo), se informará el plazo de resolución según 
                    corresponda.</p>
                    <p>D) En caso de tener que realizar un cambio se hará por la misma marca y modelo, según stock disponible de equipos 
                    y colores. Se tomará como color de preferencia el mismo adquirido al momento de la compra. En caso de no 
                    disponer, se cambia por uno de distinto color, sujeto a disponibilidad del momento.</p>
                </li>
                <li>
                    <p>Si el equipo posee falla alguna dentro de los primeros 7 días posteriores a la compra, el cambio será directo 
                    (PREVIO ANÁLISIS TÉCNICO DE 48HS HÁBILES PARA DESCARTAR PROBLEMAS AJENOS A FALLAS TÉCNICAS DE 
                    FÁBRICA). Pasado el plazo mencionado, el equipo se enviará a servicio técnico, sujeto a tiempo indeterminado de 
                    revisión.</p>
                </li>
            </ol>
         
            <hr class="col-3 col-md-2 mb-5">
            <div class="row g-5">
                <div class="col-md-6">
                    <h2 class="text-body-emphasis">Guías de uso</h2>
                    <p>Recomendaciones basicas al adquirir un nuevo equipo</p>
                    <ul class="list-unstyled ps-0">
                    
                        <li>
                            <a class="icon-link mb-1" [routerLink]="['/glossary']" fragment="primer-uso">
                                <svg class="bi" width="16" height="16">
                                    <use xlink:href="#arrow-right-circle"></use>
                                </svg>
                                Primer uso
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <h2 class="text-body-emphasis">Ayuda</h2>
                    <p>Lee instrucciones y documentación más detalladas sobre cómo usar o contribuir a hacer un buen uso.</p>
                    <ul class="list-unstyled ps-0">
                        <li>
                            <a class="icon-link mb-1" [routerLink]="['/glossary']" fragment="limpieza">
                                <svg class="bi" width="16" height="16">
                                    <use xlink:href="#arrow-right-circle"></use>
                                </svg>
                                Guía de como limpiar tu equipo
                            </a>
                        </li>
                        <li>
                            <a class="icon-link mb-1" [routerLink]="['/glossary']" fragment="palabras-claves">
                                <svg class="bi" width="16" height="16">
                                    <use xlink:href="#arrow-right-circle"></use>
                                </svg>
                                Palabras Claves
                            </a>
                        </li>
                        <li>
                            <a class="icon-link mb-1"  [routerLink]="['/glossary']" fragment="st">
                                <svg class="bi" width="16" height="16">
                                    <use xlink:href="#arrow-right-circle"></use>
                                </svg>
                                Services Oficiales
                            </a>
                        </li>
                
                       
                    </ul>
                </div>
            </div>
        </main>
      
    </div>
   

</body>
