<div class="d-flex justify-content-center align-items-center vh-100">
    <div class="card p-4 shadow" style="width: 22rem; height: auto;">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <h1 class="h4 mb-3 font-weight-normal text-center">Please sign in</h1>
        <div class="form-group mb-4">
          <label for="email">Email</label>
          <input
            id="email"
            type="email"
            formControlName="email"
            class="form-control"
            placeholder="Email address"
            [class.is-invalid]="loginForm.get('email')?.invalid && loginForm.get('email')?.touched"
          />
          <div *ngIf="loginForm.get('email')?.invalid && loginForm.get('email')?.touched" class="text-danger">
            <small *ngIf="loginForm.get('email')?.errors?.['required']">El email es requerido.</small>
            <small *ngIf="loginForm.get('email')?.errors?.['email']">Debe ser un email válido.</small>
          </div>
        </div>
        
        <div class="form-group mb-4">
          <label for="password">Contraseña</label>
          <input
            id="password"
            type="password"
            formControlName="password"
            class="form-control"
            placeholder="Contraseña"
            [class.is-invalid]="loginForm.get('password')?.invalid && loginForm.get('password')?.touched"
          />
          <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched" class="text-danger">
            <small *ngIf="loginForm.get('password')?.errors?.['required']">La contraseña es requerida.</small>
            <small *ngIf="loginForm.get('password')?.errors?.['minlength']">
              La contraseña debe tener al menos 6 caracteres.
            </small>
          </div>
        </div>
        

        <div class="form-check mb-3">
          <input type="checkbox" class="form-check-input" id="rememberMe">
          <label class="form-check-label" for="rememberMe">Remember me</label>
        </div>
        <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-primary mt-3" [disabled]="loginForm.invalid">Iniciar sesión</button>
        </div>
        <p class="mt-4 mb-0 text-muted text-center">© Cat-Bombai</p>
      </form>
    </div>
  </div>
  
